<template>
  <div>
    <smart-table ref="smartTable"
                 :headers="headers()"
                 :search-function="searchFunction"
                 preference-key="News">

      <!-- smart table slot HEADER-ITEM-1: new dialog (activate in the headers) -->
      <template v-slot:HEADER-ITEM-1>
        <news-new @updated="$refs.smartTable.getTableRows()" />
      </template>

      <!-- smart table slot results -->
      <template v-slot:results="{ item: item }">
        <!-- title -->
        <td>
          {{ item.title }}
        </td>

        <!-- description -->
        <td>
          <div class="ellipsis">{{ item.description }}</div>
        </td>

        <!-- instant -->
        <td style="white-space:nowrap">
          {{ momentDate(item.instant, 'YYYY-MM-DD HH:mm') }}
        </td>

        <!--files-->
        <td style="white-space:nowrap">
          {{ item.files.filter(file => !file.isNewsImage).length }} {{ translations.attachments }}
        </td>

        <!-- read -->
        <td style="white-space:nowrap">
          {{ item.newsEmployeeList.filter(emp => emp.read).length }} / {{ item.newsEmployeeList.filter(emp => !emp.read).length }}
        </td>

        <!-- actions -->
        <td width="100">
          <div class="d-flex justify-space-around">
            <news-edit :key="item.title" :item="item" @updated="$refs.smartTable.getTableRows()" />
          </div>
        </td>
      </template>
    </smart-table>
  </div>
</template>


<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import NewsService from '@/service/news/NewsService'

import SmartTable from '@/components/SmartTable'
import NewsEdit from '@/views/News/NewsEdit'
import NewsNew from '@/views/News/NewsNew'

export default {
  name: 'News',

  components: {
    SmartTable,
    NewsEdit,
    NewsNew
  },

  data() {
    return {
      translations: translations
    }
  },

  mixins: [MomentMixin],

  methods: {
    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'title',
        label: translations.title,
        sort: 'title'
      })

      headers.push({
        type: 'TEXT',
        name: 'description',
        label: translations.description,
        sort: 'description'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'instant',
        label: translations.date,
        sort: 'instant'
      })

      headers.push({
        type: 'LABEL',
        name: 'attachments',
        label: translations.attachments
      })

      headers.push({
        type: 'LABEL',
        name: 'read',
        label: translations.readColumn
      })

      // actions
      headers.push({
        type: 'HEADER-ITEM-1'
      })

      return headers
    },

    searchFunction(params) {
      return NewsService.search(params)
    }
  }
}
</script>


<style>
.ellipsis {
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
