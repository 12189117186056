<template>
  <v-menu ref="menu" offset-y transition="scale-transition" :close-on-content-click="false"
          :return-value.sync="input">

    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-on="on" v-bind="attrs" persistent-hint readonly class="smart-date-picker"
                    v-model="inputToString" :label="label" :hint="hint"
                    clearable @click:clear="resetInput">
      </v-text-field>
    </template>

    <!-- FIXME min="2023-06-15" max="2023-06-29" -->
    <v-date-picker scrollable no-title locale="it"
                   v-model="input" :range="range"
                   @click:date="adjustInput">
      <v-btn small text @click="resetInput">Reset</v-btn>
      <v-btn v-if="range" small color="primary" @click="saveInput">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>


<script>
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'

export default {
  name: 'SmartDatePicker',

  props: {
    value: {
      type: String
    },

    label: {
      type: String
    },

    hint: {
      type: String
    },

    range: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      input: this.defaultInputValue
    }
  },

  computed: {
    defaultInputValue() {
      return this.range ? [] : null
    },

    inputToString: {
      get() {
        if (this.input === null) {
          return ''
        }

        // format as range
        if (this.range) {
          let str = ''
          if (this.input.length > 0) {
            str += this.momentDate(this.input[0])
          }
          if (this.input.length > 1) {
            str += ' ~ ' + this.momentDate(this.input[1])
          }
          return str
        }

        // format as single
        return this.momentDate(this.input)
      },

      set() {
        this.input = this.defaultInputValue
      }
    }
  },

  watch: {
    value: {
      handler(after) {
        this.input = after || this.defaultInputValue
      },
      immediate: true
    }
  },

  mixins: [MomentMixin],

  methods: {
    updateInput() {
      this.$emit('input', this.input)
      this.$emit('change')
    },

    adjustInput() {
      // handle range
      if (this.range) {
        if (this.input.length > 0 && this.input[0] > this.input[1]) {
          const tmp = this.input[1]
          this.input[1] = this.input[0]
          this.input[0] = tmp
        }
      }

      // handle single
      else {
        this.saveInput()
      }
    },

    resetInput() {
      this.input = this.defaultInputValue
      this.saveInput()
    },

    saveInput() {
      this.$refs.menu.save(this.input)
      this.updateInput()
    }
  }
}
</script>


<style>
.smart-date-picker > .v-input__control > .v-input__slot > .v-text-field__slot {
  width: 90px !important;
}
</style>
