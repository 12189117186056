import apolloUtil from '@/service/ApolloUtil'

import newsQuery from '@/service/news/NewsQuery.gql'
import downloadNewsAttachmentQuery from '@/service/news/DownloadNewsAttachmentQuery.gql'
import saveNewsMutation from '@/service/news/SaveNewsMutation.gql'
import deleteNewsMutation from '@/service/news/DeleteNewsMutation.gql'

class NewsService {

  search(params) {
    return apolloUtil.query(newsQuery, params)
      .then((response) => response?.data?.newsList)
  }

  download(params) {
    return apolloUtil.query(downloadNewsAttachmentQuery, params)
      .then((response) => response?.data?.downloadNewsAttachment)
  }

  save(params) {
    return apolloUtil.mutate(saveNewsMutation, params, false)
  }

  delete(params) {
    return apolloUtil.mutate(deleteNewsMutation, params)
  }

}

export default new NewsService()
