import translations from '@/utils/common/translations'

const emailRegex = /.+@.+\..+/
const taxCodeRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]{1}\d{3}[A-Z]$/
const onlyDigitsRegex = /^\d*$/

export const Rules = {
  methods: {
    requiredRule: (value) => value && String(value).trim() !== '' || translations.validationRequired,

    emailRegex: () => emailRegex,
    emailRule: (value) => !value || String(value).trim() === '' || emailRegex.test(value) || translations.validationEmail,

    taxCodeRegex: () => taxCodeRegex,
    taxCodeRule: (value) => !value || String(value).trim() === '' || taxCodeRegex.test(value) || translations.validationTaxCode,

    onlyDigitsRegex: () => onlyDigitsRegex,
    onlyDigitsRule: (value) => !value || String(value).trim() === '' || onlyDigitsRegex.test(value) || translations.validationOnlyDigits,

    checkDuplicateValue: (array, key, currentIndex, value) => array.some((item, index) => item[key] === value && index !== currentIndex),
    duplicateValueRule: (array, key, currentIndex) => (value) => !Rules.methods.checkDuplicateValue(array, key, currentIndex, value) || translations.validationAlreadyExists,

    jsonValidRule(value) {
      if (!value) {
        return true
      }
      try {
        JSON.parse(value)
        return true
      } catch (error) {
        return translations.validationJSONNotValid
      }
    },

    validRequired(value, length) {
      return value && String(value).trim() !== '' && (!length || (length && value.length <= length))
    }
  }

}
