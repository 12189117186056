<template>
  <main class="ma-5">
    <v-tabs v-model="tabSelected">
      <v-tab v-for="tab in tabs()" :key="tab.route" @click="updateRoute(tab.route)">
        <v-icon class="mr-1">{{ tab.icon }}</v-icon>
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabSelected" class="elevation-2">
      <v-tab-item>
        <News />
      </v-tab-item>
    </v-tabs-items>
  </main>
</template>


<script>
import { translations } from '@/utils/common'

import News from '@/views/News/News'

export default {
  name: 'NewsPage',

  components: {
    News
  },

  data() {
    return {
      translations: translations,

      tabSelected: 0
    }
  },

  mounted() {
    const tabs = this.tabs()
    const index = tabs.findIndex(tab => tab.route === this.$route.params.tab)
    if (index === -1) {
      this.updateRoute(tabs[0].route)
    } else {
      this.tabSelected = index
    }
  },

  computed: {},

  methods: {
    tabs() {
      const tabs = []

      tabs.push({
        route: '',
        title: translations.newsTitle,
        icon: 'mdi-bulletin-board'
      })

      return tabs
    },

    updateRoute(route) {
      this.$router.push('/news/' + route)
    }
  }
}
</script>


<style>
.v-tabs > .v-tabs-bar {
  background: none !important;
}
</style>
