<template>
  <div class="smart-table-overflow">
    <!-- main content -->
    <v-card>
      <!-- workaround to import the v-data-table css -->
      <v-data-table v-if="0===1" />

      <div class="smart-table v-data-table v-data-table--fixed-header v-data-table--has-bottom theme--light">
        <div class="v-data-table__wrapper">
          <table>
            <caption></caption>
            <thead class="smart-table-header v-data-table-header">

            <!-- headers -->
            <tr>
              <th scope="row" v-for="header in headers" :key="header.name">
                <div style="display: flex; align-items: center;">
                  <!-- LABEL (no filter) -->
                  <template v-if="header.type === 'LABEL'">
                    {{ header.label }}
                  </template>

                  <!-- TEXT -->
                  <v-text-field v-if="header.type === 'TEXT'"
                                v-model="params[header.name]"
                                :label="header.label"
                                :hint="header.hint"
                                persistent-hint
                                @keydown.enter.stop="getTableRows"
                                @blur.stop="getTableRows">
                  </v-text-field>

                  <!-- SELECT -->
                  <smart-select v-if="header.type === 'SELECT'"
                                v-model="params[header.name]"
                                :label="header.label"
                                :hint="header.hint"
                                :items="header.selectItems"
                                :min-labels="header.selectMinLabels"
                                @change="getTableRows">
                  </smart-select>

                  <!-- DATE-PICKER -->
                  <smart-date-picker v-if="header.type === 'DATE-PICKER'"
                                     v-model="params[header.name]"
                                     :label="header.label"
                                     :hint="header.hint"
                                     @change="getTableRows">
                  </smart-date-picker>

                  <!-- HEADER-ITEM-1 -->
                  <slot v-if="header.type === 'HEADER-ITEM-1'" name="HEADER-ITEM-1"></slot>

                  <!-- help -->
                  <v-tooltip v-if="header.help" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" small class="smart-table-header-help">mdi-information-variant-circle-outline</v-icon>
                    </template>
                    {{ header.help }}
                  </v-tooltip>

                  <!-- sort -->
                  <div v-if="header.sort" @click="updateSort(header)" class="smart-table-header-sort ml-1">
                    <v-icon small>{{ checkSort(header) }}</v-icon>
                    {{ getSortPosition(header) }}
                  </div>
                </div>
              </th>
            </tr>
            </thead>

            <!-- results: no data -->
            <tbody v-if="results.length === 0 && !overlay">
            <tr class="no-results">
              <td :colspan="headers.length">
                {{ translations.noResultsFound }}
              </td>
            </tr>
            </tbody>

            <!-- results: with data -->
            <tbody v-else>
            <tr v-for="item in results" :key="item.key" :class="item.className" @click="goToDetail(item)">
              <slot name="results" :item="item"></slot>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- footer -->
        <div class="smart-table-footer v-data-footer">
          <!-- footer: actions -->
          <div class="smart-table-footer-actions">
            <!-- reload -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" @click="getTableRows" color="main">mdi-refresh</v-icon>
              </template>
              {{ translations.reload }}
            </v-tooltip>

            <!-- FOOTER-ITEM-1 -->
            <slot name="FOOTER-ITEM-1"></slot>
          </div>

          <!-- footer: pagination -->
          <v-pagination v-if="paginated"
                        v-model="pagination.page"
                        :length="pagination.totalPages"
                        :disabled="pagination.totalPages === 1"
                        total-visible="5" circle />

          <!-- footer: page elements -->
          <div class="smart-table-footer-pageable">
            <div>
              {{ pagination.firstEl }} - {{ pagination.lastEl }} {{ translations.paginationOf }} {{ pagination.totalElements }}
            </div>

            <div v-if="paginated">
              {{ translations.paginationPerPage }}
              &nbsp;
              <select v-model="pagination.size">
                <option v-for="item in pagination.availableSizes" :key="item">{{ item }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </v-card>


    <!-- overlay -->
    <v-overlay z-index="10000" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>


<script>
import { translations } from '@/utils/common'

import SmartDatePicker from '@/components/SmartDatePicker'
import SmartSelect from '@/components/SmartSelect'

export default {
  name: 'SmartTable',

  components: {
    SmartDatePicker,
    SmartSelect
  },

  props: {
    headers: {
      required: true,
      type: Array
    },

    searchFunction: {
      required: true,
      type: Function
    },

    preferenceKey: {
      required: false,
      type: String
    },

    paginated: {
      default: true,
      type: Boolean
    },

    detailFunction: {
      default: null,
      type: Function
    }
  },

  data() {
    return {
      translations: translations,

      params: {},
      pagination: {
        page: 1,
        totalElements: 0,
        totalPages: 1,
        size: 25,
        availableSizes: [25, 50, 100, 200],
        sorts: {}
      },
      results: [],

      overlay: false
    }
  },

  mounted() {
    if (this.preferenceKey) {
      // elaborate default param value
      const preferenceParams = this.$store.getters['preferences/get/filter'][this.preferenceKey + 'Params']
      if (preferenceParams) {
        this.headers.forEach((header) => {
          if (preferenceParams[header.name]) {
            this.params[header.name] = preferenceParams[header.name]
          }
        })
      }

      // elaborate default pagination
      const preferencePagination = this.$store.getters['preferences/get/filter'][this.preferenceKey + 'Pagination']
      if (preferencePagination) {
        this.pagination.size = preferencePagination.size
        this.pagination.sorts = preferencePagination.sorts
      }
    }

    // search data
    this.getTableRows()
  },

  methods: {
    checkSort(header) {
      const sort = this.pagination.sorts[header.sort]
      if (sort === undefined) {
        return 'mdi-sort-reverse-variant'
      }
      return sort === 'ASC' ? 'mdi-sort-descending' : 'mdi-sort-ascending'
    },

    getSortPosition(header) {
      let index = 0
      for (const key of Object.keys(this.pagination.sorts)) {
        if (key === header.sort) {
          return index + 1
        }
        index += 1
      }
      return null
    },

    updateSort(header) {
      // update sort
      const sort = this.pagination.sorts[header.sort]
      if (sort === undefined) {
        this.pagination.sorts[header.sort] = 'ASC'
      } else if (sort === 'ASC') {
        this.pagination.sorts[header.sort] = 'DESC'
      } else {
        delete this.pagination.sorts[header.sort]
      }

      // save pagination into preferences (when enabled)
      if (this.preferenceKey) {
        this.$store.dispatch('preferences/set/filter', {
          name: this.preferenceKey + 'Pagination',
          value: this.pagination
        })
      }

      // reload data with new sort
      this.getTableRows()
    },

    getSort() {
      const sort = []
      Object.keys(this.pagination.sorts).forEach((key) => {
        sort.push(`${key}:${this.pagination.sorts[key]}`)
      })
      return sort
    },

    getParams() {
      return this.params
    },

    getTableRows() {
      this.overlay = true

      // get current params
      const params = this.getParams()

      // save params into preferences (when enabled)
      if (this.preferenceKey) {
        this.$store.dispatch('preferences/set/filter', {
          name: this.preferenceKey + 'Params',
          value: params
        })
      }

      // execute search
      this.searchFunction({
          ...params,
          page: this.pagination.page - 1,
          size: this.pagination.size,
          sort: this.getSort()
        })
        .then((response) => this.updateResults(response))
        .catch(() => this.updateResults())
        .finally(() => this.overlay = false)
    },

    updateResults(data) {
      if (data === null || data === undefined) {
        this.results = []
        this.pagination.totalElements = 0
        this.pagination.totalPages = 1
        this.pagination.firstEl = 0
        this.pagination.lastEl = 0
      } else if (this.paginated) {
        this.updateResultsPaginated(data)
      } else {
        this.updateResultsNotPaginated(data)
      }
    },

    updateResultsPaginated(data) {
      this.results = data.content
      this.pagination.totalElements = data.totalElements
      this.pagination.totalPages = data.totalPages
      if (data.totalElements === 0) {
        this.pagination.firstEl = 0
        this.pagination.lastEl = 0
      } else {
        this.pagination.firstEl = this.pagination.page * this.pagination.size - this.pagination.size + 1
        this.pagination.lastEl = this.pagination.page === data.totalPages ? this.pagination.totalElements : this.pagination.page * this.pagination.size
      }
    },

    updateResultsNotPaginated(data) {
      this.results = data
      this.pagination.totalElements = data.length
      this.pagination.totalPages = 1
      this.pagination.firstEl = 1
      this.pagination.lastEl = data.length
    },

    goToDetail(item) {
      if (this.detailFunction) {
        this.detailFunction(item)
      }
    }
  },

  computed: {
    // add cloned for the watch
    paginationCloned() {
      return JSON.parse(JSON.stringify(this.pagination))
    }
  },

  watch: {
    // on cloned computed prop
    paginationCloned: {
      handler(oldV, newV) {
        // set size when not valid
        if (!this.pagination.availableSizes.includes(parseInt(this.pagination.size, 10))) {
          // eslint-disable-next-line prefer-destructuring
          this.pagination.size = this.pagination.availableSizes[0]
        }

        // reset page when size is changed or current page exceed the totalPages
        if (this.pagination.page !== 1 && (oldV.size !== newV.size || this.pagination.page > this.pagination.totalPages)) {
          this.pagination.page = 1
        }

        // save pagination into preferences (when enabled)
        if (this.preferenceKey) {
          this.$store.dispatch('preferences/set/filter', {
            name: this.preferenceKey + 'Pagination',
            value: this.pagination
          })
        }

        // reload data
        this.getTableRows()
      },
      deep: true
    }
  }
}
</script>


<style>
.smart-table-overflow {
  overflow: hidden;
}

.smart-table .v-data-table__wrapper {
  height: calc(100vh
  - 20px/* margin-top */
  - 10px/* margin-top */
  - 50px/* tab header */
  - 60px/* table footer */
  - 20px /* margin-bottom */
  );
}

.smart-table-header {
  width: 100%;
  height: 90px;
}

.smart-table.v-data-table--fixed-header thead th {
  background-color: #FCFCFC !important;
}

.smart-table-header-sort {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
}

.smart-table-header-sort .v-icon {
  padding-right: 2px;
}

.smart-table-header-help {
  padding-left: 4px;
  cursor: help;
}

.smart-table .no-results:hover {
  background: none !important;
}

.smart-table .no-results td {
  text-align: center;
  padding: 50px !important;
  cursor: default;
}

.smart-table-footer {
  width: 100%;
  height: 60px;
  padding: 0 16px !important;
  display: flex;
  justify-content: space-between;
  background-color: #FCFCFC !important;
}

.smart-table-footer > * {
  width: 33%;
}

.smart-table-footer.v-data-footer {
  margin: 0 !important;
}

.smart-table-footer .v-pagination__navigation,
.smart-table-footer .v-pagination__item {
  box-shadow: none !important;
}

.smart-table-footer .v-pagination__item--active {
  background-color: #005792 !important;
  border-color: #005792 !important;
}

.smart-table-footer-actions > * {
  margin-right: 15px;
}

.smart-table-footer-pageable {
  display: flex;
  justify-content: space-around;
  cursor: default;
}

.smart-table-footer-pageable select {
  border-bottom: 1px solid #808080;
  cursor: pointer;
}

.v-text-field .v-label {
  font-size: small;
}

.v-input input,
.v-select__selections {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: small;
}

.v-tooltip__content {
  max-width: 200px;
  background-color: #444 !important;
}
</style>
