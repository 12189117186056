<template>
  <v-select v-model="input"
            @input="updateInput"
            :label="label"
            :hint="hint"
            persistent-hint
            :items="items"
            multiple>

    <!-- select all -->
    <template v-slot:prepend-item>
      <v-list-item @mousedown.prevent @click="selectAll">
        <v-list-item-action>
          <v-icon :color="input.length > 0 ? 'primary' : ''">{{ selectAllIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ translations.all }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <!-- selected items as text -->
    <template v-slot:selection="{ item, index }">
      <template v-if="areAllSelected && index === 0">{{ translations.all }}</template>
      <template v-else-if="areSomeSelected">
        <span v-if="index < minLabels">{{ item.text }}</span>
        <span v-if="index < minLabels - 1 && index < input.length - 1">,&nbsp;</span>
        <span v-if="index === minLabels" class="grey--text">
          &nbsp;(+{{ input.length - minLabels }})
        </span>
      </template>
    </template>
  </v-select>
</template>

<script>
import { translations } from '@/utils/common'

export default {
  name: 'SmartSelect',

  props: {
    value: {
      type: Array,
      default: () => []
    },

    label: {
      type: String
    },

    hint: {
      type: String
    },

    items: {
      type: Array
    },

    minLabels: {
      type: Number,
      default: 2
    }
  },

  data() {
    return {
      translations: translations,

      input: []
    }
  },

  computed: {
    areAllSelected() {
      return this.input.length === this.items.length
    },

    areSomeSelected() {
      return this.input.length > 0 && !this.areAllSelected
    },

    selectAllIcon() {
      if (this.areAllSelected) {
        return 'mdi-checkbox-marked'
      }
      if (this.areSomeSelected) {
        return 'mdi-minus-box'
      }
      return 'mdi-checkbox-blank-outline'
    }
  },

  watch: {
    value: {
      handler(after) {
        this.input = after || []
      },
      immediate: true
    }
  },

  methods: {
    updateInput() {
      this.$emit('input', this.input)
      this.$emit('change')
    },

    selectAll() {
      this.$nextTick(() => {
        this.input = this.areAllSelected ? [] : this.items.map((c) => c.value)
        this.updateInput()
      })
    }
  }
}
</script>
